import * as Sentry from "@sentry/browser";

let environment;
switch(window.location.host.split(".")[0]){
  case "investments":
    environment = "production";
  break;
  case "acpl":
    environment = "staging";
  break;
  case "acplqa":
    environment = "qa";
  break;
}

Sentry.init({
  dsn: 'https://9115e70e332344f78f22b63a24f7dfa8@o393517.ingest.sentry.io/5260326',
  environment: environment
});
window.Sentry = Sentry
